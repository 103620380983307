<template>
  <div class="about">
    <!--   <h3 class="title is-3">Project Settings</h3>

  
    <a :href="proj.airtableUrl" target="_blank">
      <b-button rounded>Manage Endpoints spec</b-button>
    </a>

    <h3>Edit your global API configuration based on your needs.</h3>
    <br />

     -->

    <br />
    <br />

    <b-field horizontal>
      <h3 class="title is-4">About</h3>
    </b-field>

    <b-field horizontal label="  API Name  ">
      <b-input
        size="is-"
        placeholder="Real Fake Doors V3"
        v-model="proj.name"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>

    <b-field horizontal label="Unique ID">
      <b-input size="is-" placeholder="api" :value="proj.id" :disabled="true"></b-input>
    </b-field>

    <b-field horizontal label="About">
      <b-input
        type="textarea"
        placeholder="This API knows things"
        v-model="proj.desc"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>

    <b-field horizontal label="Avatar URL ">
      <b-input
        size="is-"
        placeholder="https://myapp.com/img/logo-api.png"
        v-model="proj.avatar"
        :loading="posting"
        :disabled="posting"
        type="url"
      ></b-input>
    </b-field>

    <b-field horizontal label="Website">
      <b-input
        size="is-"
        placeholder="https://myapp.com/developpers"
        v-model="proj.website"
        :loading="posting"
        :disabled="posting"
        type="url"
      ></b-input>
    </b-field>

    <b-field horizontal label="   ">
      <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
    </b-field>

    <hr />
    <b-field horizontal>
      <h3 class="title is-4">Dashboard access</h3>
    </b-field>

    <b-field horizontal>
      <b-switch v-model="proj.publicAccess" type="is-info">
        Anyone can view this API documentation
        <b-tooltip
          type="is-dark"
          multilined
          label="Your settings won't be editable, but the docs and playground will be available publicly"
        >
          <i class="fal fa-question-circle" aria-hidden="true"></i>
        </b-tooltip>
      </b-switch>
    </b-field>

    <b-field horizontal label="Viewers">
      <b-taginput
        :disabled="proj.publicAccess"
        v-model="proj.viewers"
        ellipsis
        :placeholder="!proj.publicAccess ? 'Add by email' : '( anyone )'"
        aria-close-label="Delete this tag"
      >
      </b-taginput>
    </b-field>
    <b-field horizontal label="Editors">
      <b-taginput v-model="proj.editors" ellipsis placeholder="Add by email" aria-close-label="Delete this tag">
      </b-taginput>
    </b-field>
    <b-field horizontal label="Admins">
      <b-taginput v-model="proj.admins" ellipsis placeholder="Add by email" aria-close-label="Delete this tag">
      </b-taginput>
    </b-field>
    <b-field horizontal label="   ">
      <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
    </b-field>

    <hr />
    <!-- 
    <h3 class="title is-4">Access</h3>

    <b-field>
      <b-switch v-model="proj.publicAccess" type="is-info">Anyone can access this API documentation </b-switch>
    </b-field>

    <b-field horizontal label="   ">
      <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
    </b-field>    <hr />
-->

    <!-- 

    <b-field horizontal>
      <h3 class="title is-4">Airtable</h3>
    </b-field>

    <b-field horizontal label="Airtable base ID  ">
      <b-input
        size="is-"
        placeholder="app1234"
        v-model="proj.airtableBase"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>
    <b-field horizontal label="Tab name ">
      <b-input
        size="is-"
        placeholder="endpoints"
        v-model="proj.airtableTab"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>
    <b-field horizontal label="View name ">
      <b-input
        size="is-"
        placeholder="Grid"
        v-model="proj.airtableView"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>

    <b-field horizontal label="API Key">
      <b-input
        size="is-"
        placeholder="key1234"
        type="password"
        v-model="proj.airtableApiKey"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>

    <b-field horizontal label="Shared View URL">
      <b-input
        size="is-"
        placeholder="https://airtable.com/123456GtRVXWFy6HoO"
        type="url"
        v-model="proj.airtableShareViewUrl"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>

    <b-field horizontal label="   ">
      <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
    </b-field>




  -->
    <hr />
    <b-field horizontal>
      <h3 class="title is-4">Credentials</h3>
    </b-field>

    <b-field horizontal label="projectId">
      <b-input size="is-" placeholder="api" :value="proj.id" :disabled="true"></b-input>
    </b-field>

    <b-field horizontal label="serverSecret">
      <b-input
        size="is-"
        placeholder="Midrun-Secret-1234"
        v-model="proj.serverSecret"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>

    <b-field horizontal label="   ">
      <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
    </b-field>
    <hr />

    <b-field horizontal>
      <h3 class="title is-4">Backend</h3>
    </b-field>

    <b-field horizontal label="   "> <p>Where does your API live?</p> </b-field>

    <b-field horizontal label="Production URL  ">
      <b-input
        size="is-"
        placeholder="https://api.myapp.com/v1"
        v-model="proj.apiBaseUrl"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>
    <b-field horizontal label="Dev server URL ">
      <b-input
        size="is-"
        placeholder="https://apiv2preview.myapp.com/v1"
        v-model="proj.apiBaseUrlDev"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>

    <b-field horizontal label="Backend repo">
      <b-input
        size="is-"
        placeholder="https://github.com/private/projectX"
        v-model="proj.backendRepoUrl"
        :loading="posting"
        :disabled="posting"
        type="url"
      ></b-input>
    </b-field>

    <b-field horizontal label="CI dashboard">
      <b-input
        size="is-"
        placeholder="ex: https://heroku.com/dashboard/myproject"
        v-model="proj.backendDashUrl"
        :loading="posting"
        :disabled="posting"
        type="url"
      ></b-input>
    </b-field>

    <b-field horizontal label="Database dashboard">
      <b-input
        size="is-"
        placeholder="ex: https://mongo.com"
        v-model="proj.backendDbManagerUrl"
        :loading="posting"
        :disabled="posting"
        type="url"
      ></b-input>
    </b-field>
    <b-field horizontal label="Cloudflare settings">
      <b-input
        size="is-"
        placeholder="ex: https://cloudflare.com/project/caching"
        v-model="proj.backendCloudflareUrl"
        :loading="posting"
        :disabled="posting"
        type="url"
      ></b-input>
    </b-field>

    <b-field horizontal label="Admin Dashboard">
      <b-input
        size="is-"
        placeholder="ex: https://myapp.com/admin"
        v-model="proj.externalAdminDashboardUrl"
        :loading="posting"
        :disabled="posting"
        type="url"
      ></b-input>
    </b-field>

    <b-field horizontal label="   ">
      <router-link :to="{ path: '/' + $route.params.project + '/settings/server' }">
        <b-button type="is-info is-outlined">Configure Server Settings</b-button>
      </router-link>
    </b-field>

    <b-field horizontal label="   ">
      <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
    </b-field>

    <hr />

    <b-field horizontal>
      <h3 class="title is-4">Monitoring</h3>
    </b-field>

    <b-field horizontal>
      <b-switch v-model="proj.backendMonitoring" type="is-info">
        Enable backend monitoring
        <b-tooltip type="is-dark" multilined label="Your backend server will send us requests metrics for analysis.">
          <i class="fal fa-question-circle" aria-hidden="true"></i>
        </b-tooltip>
      </b-switch>
    </b-field>

    <b-field horizontal label="Server Token">
      <div class="serverTokenGen">
        <b-button
          v-if="!generatedToken"
          type="is-dark is-outlined"
          @click="generateServerToken"
          :loading="generating"
          :disabled="generating"
          >View server token</b-button
        >

        <p v-else>
          <b-input
            size="is-"
            placeholder=" super secret....."
            v-model="generatedToken"
            :loading="posting"
            :disabled="posting"
          ></b-input>
          You'll need to add this token to your server's environment variables. This token will only be visible once.
          <!-- -->
          Set the environment variable <code>MIDRUN_API_TOKEN</code> to this value.
        </p>
      </div>
    </b-field>

    <b-field horizontal label="   ">
      <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
    </b-field>

    <hr />

    <b-field horizontal>
      <h3 class="title is-4">NPM Package</h3>
    </b-field>

    <b-field horizontal label="   ">
      <p>Public or private. The easy way to consume your API in react/vue or let others build things with it.</p>
    </b-field>

    <b-field horizontal label="npm module name ">
      <b-input
        size="is-"
        placeholder="something-api"
        v-model="proj.npmName"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>

    <b-field horizontal label="lib repo">
      <b-input
        size="is-"
        placeholder="https://github.com/my-js-sdk/projectX"
        v-model="proj.npmRepoUrl"
        :loading="posting"
        :disabled="posting"
        type="url"
      ></b-input>
    </b-field>

    <b-field horizontal label="   ">
      <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
    </b-field>

    <hr />

    <b-field horizontal>
      <h3 class="title is-4">Custom domain</h3>
    </b-field>

    <b-field horizontal label="   ">
      <p>
        Host this API on your own domain. You'll need to add a <code>CNAME</code> record for your domain pointing
        <code>cname.mid.run</code>.
      </p>
    </b-field>

    <b-field horizontal label="domain name ">
      <b-input
        size="is-"
        placeholder="ex: api.myapp.com"
        v-model="proj.midrunCloudDomain"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>

    <b-field horizontal label="   ">
      <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
    </b-field>

    <hr />

    <h3 class="title is-4">Raw JSON</h3>

    <vue-json-editor
      v-if="enableGetOptions || !isGet"
      v-model="proj"
      mode="code"
      :show-btns="false"
      :expandedOnStart="true"
      @json-change="onJsonPostDataChange"
    ></vue-json-editor>

    <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
  </div>
</template>

<script>
//import InvitationModule  from '../../../lib/invitation-module/module.js' ;
// InvitationModule = require('./../lib/invitation-module/module.js');
//any ways to make it available everywhere?
/*
var server = InvitationModule.client;

window.a = server;
window.api = server;
window.e = InvitationModule.endpoints;
*/

//import VueJsonPretty from "vue-json-pretty";
//import "vue-json-pretty/lib/styles.css";
import vueJsonEditor from "vue-json-editor";

//import { Path } from "path-parser";

import { MethodColors } from "@/common/utils.js";

export default {
  // name: "API-explore",
  components: {
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    vueJsonEditor,
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Settings",
  },
  props: {
    proj: Object, //
  },
  methods: {
    save: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.proj;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };
      window.API.editProject(d.id, d).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },
    generateServerToken: function () {
      this.generating = true;
      window.API.generateServerToken(this.proj.id).then((token) => {
        this.generating = false;
        this.generatedToken = token;
        //proj updated!
      });
    },
  },
  data() {
    return {
      posting: false,
      generating: false,
      generatedToken: null,
    };
  },
  computed: {
    airtableEditUrl() {
      var p = this.proj;
      var autoAirtable = p.airtableBase ? "https://airtable.com/" + p.airtableBase : null;
      return p.airtableUrl || autoAirtable;
    },
  },
};
</script>
<style scoped>
h3.title.is-4 {
  /* sexy */
  font-size: 33px;
  font-weight: 100;
  line-height: 2em;
  margin-top: 0.5em;
}
</style>
